@import "_variables";

$theme-colors: (
  "primary": $forestGreen,
  "info": $info,
  "danger": $danger,
  "warning": $warning,
);
/*"primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark */
@import "../../node_modules/bootstrap/scss/bootstrap";
