/* Variables - not sure how to share these across sass files yet - if changed, change in 
               all files in the 'scss' folder 
-------------------------------------------------- */
@import "_variables";

html {
  font-size: 14px;
  position: relative;
  min-height: 100%;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

body {
  margin: 0 0 60px 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  .brandingSection {
    background-color: $forestGreen;
    padding: 10px 0;
    margin-bottom: 10px;
    a {
      color: $forestGreen;
    }
    .col-sm {
      padding: 0 25px;
    }
    span.greeting {
      font-size: 2em;
      display: block;
      color: #fff;
    }
    .dropdown {
      display: inline-block;
      margin-left: 25px;
    }
    .dropdown-menu a {
      color: $darkGray;
    }
  }

  //   .navbar {
  //     padding: 0;
  //     margin: 0;
  //     .container div {
  //       background-color: lighten($orange, 5%);
  //       width: 100%;
  //       border: solid 0px $orange;
  //       border-width: 7px 0;
  //     }
  //     a {
  //       color: #fff !important;
  //       font-weight: bold;
  //       white-space: nowrap;
  //       &:hover {
  //         background-color: darken($orange, 5%);
  //         color: #fff !important;
  //       }
  //       &.active {
  //         background-color: #fff;
  //         color: darken($orange, 10%) !important;
  //       }
  //     }
  //   }
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  white-space: nowrap;
  line-height: 40px; /* Vertically center the text there */
  .container {
    background-color: $forestGreen;
    color: #fff;
    a {
      color: #fff;
    }
  }
}

/* ************************************** 
        NOTIFICATION SLIDER THING
************************************** */
#NotificationSliderWrapper {
  position: relative;
  margin-left: auto;
  margin-right: 0;
  margin-top: 0;
  padding: 0;
  width: 25%;
  height: 100%;
  z-index: 1000;
}

#NotificationSlider {
  position: absolute;
  top: 2px;
  right: -100%;
  width: 100%;
  height: 80vh;
  padding: 0;
  margin: 0;
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.slide-out {
  animation: slide-out 1s forwards;
  -webkit-animation: slide-out 1s forwards;
}

.slide-in {
  animation: slide-in 1s forwards;
  -webkit-animation: slide-in 1s forwards;
}

@keyframes slide-out {
  99% {
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

@-webkit-keyframes slide-out {
  99% {
    opacity: 1;
  }
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(0%);
    opacity: 0;
  }
  1% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(0%);
    opacity: 0;
  }
  1% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-100%);
  }
}
