// $blue: #06368b;
// $orange: #ff671e;
$gray: #cccccc;
$lightGray: #eaeaea;
$darkGray: #333;
$tan: #b2a184;
$forestGreen: #113d24;
$info: #b58900;
$danger: #cb4b16;
$warning: #fd7e14;
