@import "_variables";

.striped-cols .col:nth-child(even) {
  background-color: #ddd;
}

.panel-info-section {
  background-color: scale-color(
    $color: $forestGreen,
    $saturation: -50%,
    $lightness: 30%
  );
  color: #fff;
}
